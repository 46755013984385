<template>
    <div>
        <Head :title="'车辆离场'" :out="'SecurityManagement'" />
        <div class="warper">
            <div class="queryCode" v-if="!ids">
                <h2>请输入提车验证码</h2>
                <input type="text" v-model="code">
                <br>
                <br>
                <br>
                <br>
                <van-button type="info" @click="go" style="width:150px">下一步</van-button>
            </div>
            <div v-else>
                <infoData :dataInfo="dataInfo" />
            </div>
        </div>
    </div>
</template>

<script>
import Head from '@/components/Head'
import {collectCar} from '@/api/out'
import infoData from './infoData'
    export default {
        components: {
            Head,infoData
        },
        data() {
            return {
                code:"",
                dataInfo:{},
                ids:false
            }
        },
        methods: {
            async go() {
                try{
                    const {data} = await collectCar({code:this.code}).then(res=>res)
                    this.dataInfo = data.data
                    this.ids = true
                }
                catch{}
            }
        },
    }
</script>

<style lang="scss" scoped>
.warper{
    margin-top: 50px;
    .queryCode{
        
        text-align: center;
        margin-top: 50%;
    }
}
</style>