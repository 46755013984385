<template>
    <div>
        <br>
        <van-form validate-first>
            <van-field :value="dataInfo.picker" label="联系人"/>
            <van-field :value="dataInfo.phone" label="联系电话"/>
            <van-field :value="dataInfo.id_number" label="提车人身份证"/>
            <van-field :value="dataInfo.leave_time" label="预约离场时间"/>
        </van-form>
        <van-image width="100%" height="100%" :src="dataInfo.image_script_url"/>
        <br>
        <br>
        <p>
            请提车人微信扫码进行车辆交接：
        </p>
        <p>
            <van-image width="100%" height="100%" :src="dataInfo.qrcode"/>
        </p>
        <div class="btn">
            <van-button type="info" @click="goMa">核验无误   下一步</van-button>
        </div>
        <van-dialog v-model="show" title="标题">
            <div class="tac">

                <img :src="img"  />
            </div>
        </van-dialog>
    </div>
</template>

<script>
import {qrCode} from '@/api/out'
    export default {
        props:['dataInfo'],
        data() {
            return {
                show:false,
                img:""
            }
        },
        methods: {
             goMa() {
                this.$router.push({path:'/outImage',query:{id:this.dataInfo.id}})
            }
        },
    }
</script>

<style lang="scss" scoped>
.btn{
    text-align: center;
    width: 100%;
    .van-button{
        width: 200px;
    }
}
.tac{
    text-align: center;
}
</style>