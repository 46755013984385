import request from './request'

//工序工位
export function collectCar(data) {
  return request({
    url: '/index.php/filter/collect_car',
    method: 'post',
    data
  })
}
export function qrCode(data) {
  return request({
    url: '/index.php/filter/qr_code',
    method: 'post',
    data
  })
}
export function checkInformationFind(data) {
  return request({
    url: '/index.php/api/check_find',
    method: 'post',
    data
  })
}
export function submitCheck(data) {
  return request({
    url: '/index.php/api/submit_check',
    method: 'post',
    data
  })
}


